import emailjs from "@emailjs/browser";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Wrapper from "../../resources/components/general/Wrapper";
import HeaderBG from "../../resources/images/reporting-channel/header-bg.svg";
import {ReactComponent as EmailSend} from "../../resources/images/reporting-channel/emailD.svg";
import {ReactComponent as GesbaGreen} from "../../resources/images/reporting-channel/logo_gesba_green.svg";
import {ReactComponent as GesbaGreen2} from "../../resources/images/reporting-channel/logo_gesba_green_centro.svg";
import { ReactComponent as FondoGesbaGreen } from "../../resources/images/reporting-channel/fondo_Canal_denúncias.svg";
import { ReactComponent as FondoGesbaGreen2 } from "../../resources/images/reporting-channel/fondo_Canal_denúncias2.svg";
import { SimpleTextureBackground } from "../home/styles/home_styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LinearProgress  from "@mui/material/LinearProgress";


const DocumentHero = styled.div`
  width: 100%;
  position: relative;
  background-color: #1c6c5e;
  backdrop-filter: blur(5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  /* min-height: 40vh; */
  z-index: 10;
  padding: 20px 0;

  &::before {
    background-image: url(${HeaderBG});
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    display: block;
    width: 100vw;
    height: 210px;
    bottom: -30px;
    position: absolute;
    z-index: -1;
    background-position-x: center;
    background-position-y: bottom;
  }
`;

function ReportingChannel() {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      faith: false, contract: false, anonymous: "Não",
    },
  });
  const form = useRef();

  const relations = [
    { value: "Colaborador", label: "Colaborador" },
    { value: "Ex-Colaborador", label: "Ex-Colaborador" },
    { value: "Cliente", label: "Cliente" },
    { value: "Parceiro", label: "Parceiro" },
    { value: "Fornecedor", label: "Fornecedor" },
    { value: "Outro", label: "Outro" },
  ];

  const subjects = [
    { value: "Corrupção e conflitos de interesse" },
    { value: "Ética e boas práticas" },
    { value: "Proteção de dados" },
    { value: "Outro" },
  ];
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose2= () => {
    setOpen2(false);
    window.scrollTo({
      top: 0,          
      behavior: "smooth" 
    });
  };
  

  const onSubmit = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        "template_dg1gu1i",
        {
          //from_name: data.anonymous ? "" : data.name,
          //from_email: data.anonymous ? "" : data.email,
          //phone_number: data.anonymous ? "" : data.telephone,
          from_name: data.anonymous === "Não" ? data.name : "",
          from_email: data.anonymous === "Não" ? data.email : "",
          phone_number: data.anonymous === "Não" ? data.telephone: "",
          report_subject: data.subject,
          relationship_origin: data.relation,
          report_date: data?.date?.format("DD/MM/YYYY"),
          report_description: data.description,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        () => {
          onSubmit1(data);
        },
        () => { 
          toast("Erro ao enviar denúncia");
        }
      );
  };
  const onSubmit1 = (data) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE,
        "template_w6emzgl",
        {
          from_name: data.name,
          from_email:data.email,
          phone_number:data.telephone,
          report_subject: data.subject,
          relationship_origin: data.relation,
          report_date: data?.date?.format("DD/MM/YYYY"),
          report_description: data.description,
        },
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        () => {
          //toast("Denúncia enviada com sucesso");
          form.current.reset();
          handleClickOpen();
          handleReset()
        },
        () => {
          form.current.reset();
          handleClickOpen();
          handleReset()
        }
      );
  };
  const [progress, setProgress] = useState(0); 
  const handleReset = () => {
    reset({
      relation: "",
      faith: false,
      contract: false,
      anonymous: "Não",

    });
  };
  
  useEffect(()=>{
   // handleClickOpen();
  if(open ===! false) {
  if (progress < 100) {
    const timer = setInterval(() => {
      setProgress((prevProgress) => Math.min(prevProgress + 20, 100)); 
    }, 200); 

    return () => clearInterval(timer); 
  } else {
    setOpen(false); 
    handleClose();
    handleClickOpen2();
    setProgress(0)
  }}
}, [progress, open]); 

/*handleClose();
 handleClickOpen2();*/

  return (
    <>
      <Dialog 
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open2} 
        maxWidth={"xl"}
        min
        style={{minHeight:"calc(100% - 64px)",paddingTop:"50px",}} 
        PaperProps={{
          style: {
            borderRadius: "20px", 
          },
        }}
      >

          
      <GesbaGreen style={{margin:"20px 20px 0px 20px",zIndex:9000}}/> 
      <div style={{width:"100%", flexDirection:"row", justifyContent:"space-between", zIndex:9000, padding: "20px"}}>       
          
         <IconButton
            aria-label="close"
            onClick={handleClose2}
            sx={(theme) => ({
              position: 'absolute',
              right: 28,
              top: 28,

              color: theme.palette.grey[500],
            })}
          >
            <CloseIcon />
          </IconButton>
      </div>
      <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0, // Places background below other components
    }}
  >
    <FondoGesbaGreen2 style={{width: "823px",minHeight: "716px"}}/>
  </div>

        <DialogContent style={{justifyContent:"center", alignItems:"center", zIndex:9000, overflow:"visible"}}>
          <Typography style={{textAlign: "center", color: "#172726", ustifyContent: "center",alignItems: "center", width: "100%",fontSize: "2rem",fontWeight: "bold", fontFamily: "Arial, sans-serif",}} >
          Denúncia enviada com sucesso!
          </Typography>
          <Typography style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%", marginTop:"-10px"}}>
           <EmailSend style={{width:"60%"}}/>
           </Typography>

          <Typography style={{color: "#1C6C5E", fontSize: "1.25rem",fontWeight: "bold",textAlign: "center"}}>
          A sua denúncia foi enviada com sucesso!
          </Typography>
          <Typography style={{color: "#1C6C5E", fontSize: "17px",textAlign: "center",margin:"10px 70px 0 ",fontWeight: 450,}}>
          Vamos analisá-la cuidadosamente e entraremos em contacto consigo brevemente. 
          <Typography style={{color: "#1C6C5E", fontSize: "17px",textAlign: "center",fontWeight: 450,}}>
          Obrigado pela sua colaboração!
          </Typography>
          </Typography>
          </DialogContent>
        <DialogActions style={{justifyContent:"center", alignItems:"center", width:"100%"}}>
          <Button onClick={() => (window.location.href = "https://gesba.pt")} style={{backgroundColor: "#1C6C5E", color: "#fff", display: "flex",justifyContent: "center",alignItems: "center",borderRadius: "5px",width: "50%",margin: " 0px 0 40px 0",fontWeight: "bold",}}>
          Página Inicial
          </Button>
        </DialogActions>
        
      </Dialog>
      ´
      <Dialog 
        //onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open} 
        maxWidth={"xl"}
        min
        style={{maxHeight:"calc(100% - 64px)",paddingTop:"50px",}}
        PaperProps={{
          style: {
            borderRadius: "20px", 
          },
        }} >
          <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 0, // Places background below other components
    }}
  >
    <FondoGesbaGreen style={{width: "783px",height: "532px"}}/>
  </div>
        <DialogContent style={{display: "flex",justifyContent:"center", alignItems:"center", width:"100%", overflow:"visisle"}}>
          <GesbaGreen2 style={{justifyContent:"center", alignItems:"center", width:"65%", marginTop:"50px",zIndex: 90,}}/>
        </DialogContent>
        <DialogContent style={{justifyContent:"center", alignItems:"center", width:"100%",zIndex: 90}}>
        <Typography style={{color: "#1C6C5E", fontSize: "20px",fontWeight: "bold",textAlign: "center",}}>
          A sua denúncia está a ser enviada!
          </Typography>
          </DialogContent>
          
        <DialogActions style={{justifyContent:"center", alignItems:"center", width:"100%", marginBottom:"100px"}}>
        <LinearProgress variant="determinate" value={progress} style={{width: "80%",height: "10px",  borderRadius: "5px", backgroundColor: "#d9d9d9",}} sx={{"& .MuiLinearProgress-bar": { backgroundColor: "#1C6C5E",},}}/>
        </DialogActions>
        
      </Dialog>

      <DocumentHero className="min-h-[30vh] md:min-h-[40vh]">
        <Wrapper closeFull>
          <div className="pt-[100px] flex flex-col max-w-5xl mx-auto">
            <h3 className="text-4xl md:text-6xl text-white font-bold text-center">
              Canal de Denúncias
            </h3>
            <p className="text-md md:text-lg text-white font-semibold mt-10 text-center">
              O canal de denúncias da Gesba - Empresa De Gestão Do Sector Da
              Banana Lda., foi estabelecido em conformidade com a legislação
              vigente aplicável e boas práticas corporativas, reforçando o
              posicionamento da Gesba com os princípios transparecidos no Código
              de Conduta da Gesba. Esta página destina-se a receber denúncias,
              relatos de suspeitas ou de conhecimento efetivo de transgressões
              que estejam a ocorrer ou que possam vir a ocorrer na sequência de
              atos ou omissões praticados no contexto das atividades na Gesba -
              Empresa De Gestão Do Sector Da Banana Lda.
            </p>
            <p className="text-md md:text-lg text-white font-semibold mt-10 text-center">
              Para apresentar uma denúncia, deverá preencher o formulário
              disponibilizado abaixo.
            </p>
          </div>
        </Wrapper>
      </DocumentHero>
      <SimpleTextureBackground className="py-20 min-h-[40vh]">
        <div className="mx-auto bg-white shadow-md w-11/12 2xl:2-[1400px] py-4 px-5 max-w-5xl">
          <h3 className="text-[#1C6C5E] text-base md:text-xl font-semibold">
            Como processamos as suas denúncias?
          </h3>
          <div className="mt-3">
            <p className="text-[#707070] font-medium text-base md:text-lg">
              1. Irá receber confirmação de submissão da sua denúncia.
            </p>
            <p className="text-[#707070] font-medium text-base md:text-lg mt-2">
              2. Segue-se o período de investigação da denúncia.
            </p>
            <p className="text-[#707070] font-medium text-base md:text-lg mt-2">
              3. No prazo máximo de 3 meses receberá uma resposta ofical.
            </p>
          </div>
        </div>
        <div className="max-w-5xl mx-auto w-11/12 mt-12">
          <h3 className="text-2xl md:text-4xl text-center text-[#1C6C5E] font-medium">
            Formulário de Denúncia
          </h3>
          <form ref={form} className="mt-9" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-3">
              <p className="text-[#656565] text-base md:text-lg">
                Pretende fazer uma denúncia anónima?
                <span className="text-[#ED1D24]">*</span>
              </p>
              <div className="flex flex-col gap-1">
                <Controller
                  name="anonymous"
                  defaultValue={"Não"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                    value={value}
                      name="anonymous"
                      onChange={onChange}
                    >
                      <FormControlLabel
                        sx={{
                          color: "#A0A8B1",
                        }}
                        value="Não"
                        control={
                          <Radio
                            sx={{
                              color: "#1C6C5E",
                              "&.Mui-checked": {
                                color: "#1C6C5E",
                              },
                            }}
                          />
                        }
                        label="Não"
                      />
                      <FormControlLabel
                        sx={{
                          color: "#A0A8B1",
                        }}
                        value="Sim"
                        control={
                          <Radio
                            sx={{
                              color: "#1C6C5E",
                              "&.Mui-checked": {
                                color: "#1C6C5E",
                              },
                            }}
                          />
                        }
                        label="Sim"
                      />
                    </RadioGroup>
                  )}
                />
              </div>
            </div>
            {/* register your input into the hook by invoking the "register" function */}

            {/* include validation with required or other standard HTML validation rules */}
            {watch("anonymous") !== "Sim" && (
              <div className="flex flex-col mt-6 gap-2">
                <p className="text-[#656565] text-base md:text-lg">Nome</p>
                <input
                  placeholder="Nome Completo"
                  className="h-14 py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                  {...register("name")}
                />
              </div>
            )}
            {watch("anonymous") !== "Sim" && (
              <div className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col mt-6 basis-1/2 gap-2">
                  <p className="text-[#656565] text-base md:text-lg">
                    Email<span className="text-[#ED1D24]">*</span>
                  </p>
                  <input
                    placeholder="O seu email"
                    className="h-14 py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                    {...register("email", {
                      required: "Email obrigatório",
                      pattern: {
                        // eslint-disable-next-line
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Email inválido",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="text-[#ED1D24]">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col mt-6 basis-1/2 gap-2">
                  <p className="text-[#656565] text-base md:text-lg">
                    Telefone<span className="text-[#ED1D24]">*</span>
                  </p>
                  <div className="flex flex-row w-full">
                    <div className="flex rounded-l-[4px] bg-[#F8FAFB] border-[#EDEDED] -mr-px border-[1px] flex-col items-center justify-center px-2">
                      +351
                    </div>
                  <Controller
                      name="telephone"
                      control={control}
                      rules={{
                        minLength: {
                          value: 9,
                          message: "Número de telefone inválido",
                        },
                        maxLength: {
                          value: 9,
                          message: "Número de telefone inválido",
                        },
                        required: "Número de telefone obrigatório",
                        pattern: {
                          value: /^9[1236][0-9]{7}|2[1-9]{1,2}[0-9]{7}$/,
                          message: "Número de telefone inválido",
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                        
                          sx={{
                            "& fieldset": {
                              borderColor: "#e5e7eb",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              outline: "none",
                              boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)",
                              "& fieldset": {
                                border: "1px solid #07146380",
                              },
                            },
                            flexGrow: 1,
                          }}
                          className="h-14 bg-white rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                          displayEmpty
                          onChange={onChange}
                          value={value}
                          placeholder="291701050"
                        />
                        
                      )}
                    />
                  </div>
                  {errors.telephone && (
                    <span className="text-[#ED1D24]">
                      {errors.telephone.message}
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col mt-6 basis-1/2 gap-2">
                <p className="text-[#656565] text-base md:text-lg">
                  Assunto da Denúncia<span className="text-[#ED1D24]">*</span>
                </p>
                <Controller
                  name="subject"
                  control={control}
                  rules={{ required: "Assunto obrigatório" }}
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      sx={{
                        "& fieldset": {
                          borderColor: "#e5e7eb",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          outline: "none",
                          boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)",
                          "& fieldset": {
                            border: "1px solid #07146380",
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected?.length > 0) return selected;
                        return (
                          <span className="text-[#A0A8B1]">
                            Escolha uma opção
                          </span>
                        );
                      }}
                      className="h-14 bg-white rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                      displayEmpty
                      onChange={onChange}
                      value={value}
                    >
                      <MenuItem disabled className="text-[#A0A8B1]" value="">
                        Escolha uma opção
                      </MenuItem>
                      {subjects?.map((el) => {
                        return (
                          <MenuItem
                            key={el.value}
                            className="text-[#000]"
                            value={el.value}
                          >
                            {el.value}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors.subject && (
                  <span className="text-[#ED1D24]">
                    {errors.subject.message}
                  </span>
                )}
              </div>
              <div className="flex flex-col mt-6 basis-1/2 gap-2">
                <p className="text-[#656565] text-base md:text-lg">
                  Qual a sua relação com a Gesba?
                  <span className="text-[#ED1D24]">*</span>
                </p>
                <Controller
                  defaultValue={""}
                  name="relation"
                  control={control}
                  rules={{ required: "Relação obrigatório" }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      sx={{
                        "& fieldset": {
                          borderColor: "#e5e7eb",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          outline: "none",
                          boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)",
                          "& fieldset": {
                            border: "1px solid #07146380",
                          },
                        },
                      }}
                      renderValue={(selected) => {
                        if (selected?.length > 0)
                          return relations.filter(
                            (el) => el.value === selected
                          )[0].label;
                        return (
                          <span className="text-[#A0A8B1]">
                            Escolha uma opção
                          </span>
                        );
                      }}
                      className="h-14 bg-white rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                      displayEmpty
                      onChange={onChange}
                      value={value}
                    >
                      <MenuItem disabled className="text-[#A0A8B1]" value="">
                        Escolha uma opção
                      </MenuItem>
                      {relations?.map((el) => {
                        return (
                          <MenuItem
                            key={el.value}
                            className="text-[#000]"
                            value={el.value}
                          >
                            {el.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                {errors.relation && (
                  <span className="text-[#ED1D24]">
                    {errors.relation.message}
                  </span>
                )}
              </div>
            </div>

            <div className="flex flex-col mt-6 gap-2 w-full">
              <p className="text-[#656565] text-base md:text-lg">
                Data da Ocorrência
              </p>
              <Controller
                defaultValue={null}
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      maxDate={moment()}
                      minDate={moment("2008/01/01")}
                      sx={{
                        "& fieldset": {
                          borderColor: "#e5e7eb",
                        },
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          outline: "none",
                          boxShadow: "0 4px 3px rgb(0 0 0 / 0.1)",
                          "& fieldset": {
                            border: "1px solid #07146380",
                          },
                        },
                        width: "100%",
                      }}
                      className="w-fit bg-white rounded-[4px] focus-visible:drop-shadow-lg"
                      onChange={(value) => {
                        onChange(value);
                      }}
                      value={value}
                    />
                  </LocalizationProvider>
                )}
              />
            </div>

            <div className="flex flex-col mt-6 basis-1/2 gap-2">
              <p className="text-[#656565] text-base md:text-lg">
                Descrição da Denúncia<span className="text-[#ED1D24]">*</span>
              </p>
              <textarea
                rows={10}
                placeholder="Escreva aqui"
                className="resize-none py-2 px-5 rounded-[4px] focus-visible:drop-shadow-lg focus-visible:outline-none border-[1px] focus-visible:border-[#07146380]"
                {...register("description", {
                  required: "Descrição obrigatória",
                })}
              />
              {errors.description && (
                <span className="text-[#ED1D24]">
                  {errors.description.message}
                </span>
              )}
            </div>

            <div className="flex flex-col mt-6 basis-1/2 gap-2 bg-[#07146305] rounded-[4px] py-1 px-5 pb-3">
              <Controller
                name="faith"
                control={control}
                rules={{ required: "Obrigatório" }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        sx={{
                          color: "#1C6C5E",
                          "&.Mui-checked": {
                            color: "#1C6C5E",
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-[#656565] text-base select-none mt-2">
                        Declaro que esta denúncia foi submetida em boa fé.
                        <span className="text-[#ED1D24]">*</span>
                      </span>
                    }
                  />
                )}
              />
              {errors.faith && (
                <span className="text-[#ED1D24]">{errors.faith.message}</span>
              )}

              <Controller
                name="contract"
                control={control}
                rules={{ required: "Obrigatório" }}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      alignItems: "flex-start",
                    }}
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        sx={{
                          color: "#1C6C5E",
                          "&.Mui-checked": {
                            color: "#1C6C5E",
                          },
                        }}
                      />
                    }
                    label={
                      <span className="text-[#656565] text-base select-none mt-2">
                        Declaro que aceito que os meus dados pessoais sejam
                        processados para tratamento e arquivo, de acordo com os
                        termos previstos na lei, no âmbito das obrigações legais
                        relativas ao regime da proteção de denunciantes.
                        Adicionalmente a Gesba - Empresa De Gestão Do Sector Da
                        Banana Lda, tratamos os dados pessoais de forma leal e
                        transparente, de acordo com os seus fundamentos de
                        licitude e com finalidades especificas e em cumprimento
                        com o regime relativo ao Regulamento Geral sobre a
                        Proteção de Dados (RGPD).
                        <span className="text-[#ED1D24]">*</span>
                      </span>
                    }
                  />
                )}
              />
              {errors.contract && (
                <span className="text-[#ED1D24]">
                  {errors.contract.message}
                </span>
              )}
            </div>

            <input
              className="bg-[#1C6C5E] text-[#FFFFFF] mt-10 w-full md:w-fit mx-auto md:px-20 py-3 text-lg md:text-2xl font-semibold block shadow-sm rounded-md cursor-pointer hover:shadow-md"
              type="submit"
              value="Submeter Denúncia"
            />
          </form>
          <ToastContainer
            position="bottom-center"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
            theme="dark"
          />
        </div>
      </SimpleTextureBackground>
      
    </>
  );
}

export default ReportingChannel;
